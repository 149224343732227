import {
  Typography,
  Box,
  CardMedia,
  makeStyles,
  Button,
} from "@material-ui/core"
import React from "react"
import Img from "gatsby-image"
import { DigicLink } from "../digic-link"

const useStyles = makeStyles(theme => ({
  buttonWrapper: {
    width: "200px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  button: {
    width: "100%",
  },
  description: { marginBottom: "32px" },
  content: {
    width: "50vw",
    minHeight: "800px",
    display: "flex",
    position: "absolute",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "32px",
    backgroundColor: "rgba(1, 0, 0, 0.8)",
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
      minHeight: "600px",
      padding: "32px",
    },
  },
  background: {
    display: "flex",
    width: "100vw",
    height: "800px",
    [theme.breakpoints.down("sm")]: {
      minHeight: "600px",
    },
  },
}))

const Philosophy = ({ philo }) => {
  const classes = useStyles()
  return (
    <CardMedia className={classes.background}>
      <Img
        fluid={philo.JoinUsPhilosophyBg.imageFile.childImageSharp.fluid}
        className={classes.background}
      />
      <Box className={classes.content}>
        <Box maxWidth="700px" display="flex" flexDirection="column">
          <Typography
            variant="h2"
            style={{ marginBottom: "16px" }}
            color="primary"
          >
            {philo.JoinUsPhilosophyTitle}
          </Typography>
          <Typography variant="body1" className={classes.description}>
            {philo.JoinUsPhilosophyDescription}
          </Typography>
          <Box className={classes.buttonWrapper}>
            <DigicLink to="/job-shorts" className={classes.link}>
              <Button variant="outlined" className={classes.button}>
                Job shorts
              </Button>
            </DigicLink>
          </Box>
        </Box>
      </Box>
    </CardMedia>
  )
}

export default Philosophy
