import { Typography, Box, makeStyles, Button } from "@material-ui/core"
import React from "react"
import { useOpenHrDialog } from "../utils/hr-dialog"

const useStyles = makeStyles(theme => ({
  videoContainer: {
    position: "absolute",
    width: "100%",
    height: "45vw",
    [theme.breakpoints.down("sm")]: {
      height: "80vh",
    },
    minWidth: "100%",
    objectFit: "cover",
  },
  videoTitleWrapper: {
    height: "45vw",
    [theme.breakpoints.down("sm")]: {
      height: "80vh",
    },
  },
  videoTitleContainer: {
    height: "45vw",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 16px",
    [theme.breakpoints.down("sm")]: {
      height: "80vh",
    },
  },
  button: {
    boxShadow: "none",
    textDecoration: "none !important",
    color: "#010000",
    fontSize: "16px",
    textTransform: "uppercase",
    fontWeight: "700",
    paddingLeft: "22.5px",
    paddingRight: "22.5px",
    paddingTop: "15.5px",
    paddingBottom: "15.5px",
    backgroundColor: "#F26522",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#FF6a24",
    },
    "&:active": {
      boxShadow: "none",
    },
  },
}))

const Hero = ({ hero }) => {
  const classes = useStyles()
  const openHrDialog = useOpenHrDialog()
  return (
    <Box className={classes.videoTitleWrapper}>
      <Box
        position="absolute"
        width="100%"
        zIndex="2"
        justifyContent="center"
        alignItems="center"
      >
        <Box className={classes.videoTitleContainer}>
          <Typography align="center" variant="h1" style={{ marginBottom: 8 }}>
            {hero.JoinUsHeroTitle}
          </Typography>
          <Typography align="center" variant="h3">
            {hero.JoinUsHeroLead}
          </Typography>

          <Button
            style={{ marginTop: "24px" }}
            variant="contained"
            onClick={openHrDialog}
          >
            {hero.JoinUsHeroButton}
          </Button>
        </Box>
      </Box>
      <video
        autoPlay={true}
        muted={true}
        loop={true}
        width="100%"
        id="top-video"
        className={classes.videoContainer}
        src={hero.JoinUsHeroVideoLink}
      />
    </Box>
  )
}

export default Hero
