import React from "react"

function Dote() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="5"
      height="5"
      fill="none"
      viewBox="0 0 5 5"
      style={{ marginRight: "12px" }}
    >
      <circle cx="2.5" cy="2.5" r="2.5" fill="#F26522"></circle>
    </svg>
  )
}

export default Dote
