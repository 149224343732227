import { Typography, Box, List, ListItem, makeStyles } from "@material-ui/core"
import Img from "gatsby-image"
import Dote from "../services/dote"
import React from "react"

const useStyles = makeStyles(theme => ({
  mainWrapper: {
    display: "flex",
    width: "100vw",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  textWrapper: {
    width: "50vw",
    display: "flex",
    flexDirection: "column",
    padding: "32px",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(1, 0, 0, 0.8)",
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
    },
  },
  imageWrapper: {
    display: "flex",
    flexWrap: "wrap",
    width: "50vw",
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
    },
  },
  image: {
    width: "33.33%",
    [theme.breakpoints.down("sm")]: {
      width: "25%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "25%",
    },
  },
  line: {
    fontSize: "16px",
    color: "#F4F5F6",
    paddingLeft: "0px",
    textTransform: "uppercase",
  },
}))

const WellBeing = ({ well }) => {
  const classes = useStyles()
  return (
    <Box className={classes.mainWrapper}>
      <Box className={classes.textWrapper}>
        <Box maxWidth="700px" display="flex" flexDirection="column">
          <Typography
            variant="h2"
            style={{ marginBottom: "16px" }}
            color="primary"
          >
            {well.EmployeeWellTitle}
          </Typography>
          <Typography variant="body1">
            {well.EmployeeWellDecsription}
          </Typography>
          <List>
            {well.EmployeeWellPoints.map(({ PointTitle, PointDescription }) => (
              <ListItem className={classes.line}>
                <Dote />
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  {PointTitle}
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: "18px",
                      textTransform: "none",
                    }}
                  >
                    {PointDescription}
                  </Typography>
                </Box>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
      <Box className={classes.imageWrapper}>
        {well.EmployeeGallery.map(({ EmployeeImage }) => (
          <Img
            className={classes.image}
            fluid={EmployeeImage.imageFile.childImageSharp.fluid}
          />
        ))}
      </Box>
    </Box>
  )
}

export default WellBeing
