import { Typography, Box, makeStyles } from "@material-ui/core"
import React from "react"
import Fact from "./fact"

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: "128px 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      padding: "64px 0",
    },
  },
  factContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0 16px",
    },
  },
}))

const FactsContainer = ({ facts }) => {
  const classes = useStyles()
  return (
    <Box className={classes.wrapper}>
      <Typography variant="h2" align="center" style={{ marginBottom: "16px" }}>
        {facts.JoinUsFactTitle}
      </Typography>
      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="center"
        maxWidth="1200px"
        marginTop="50px"
      >
        {facts.FactCards.map(
          ({ FactCardTitle, FactCardDescription, FactCardBg }) => (
            <Box key={FactCardTitle} className={classes.factContainer}>
              <Fact
                title={FactCardTitle}
                description={FactCardDescription}
                image={FactCardBg}
              />
            </Box>
          )
        )}
      </Box>
    </Box>
  )
}

export default FactsContainer
