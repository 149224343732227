import {
  Typography,
  Box,
  CardMedia,
  makeStyles,
  Button,
} from "@material-ui/core"
import Img from "gatsby-image"
import React from "react"
import { useOpenHrDialog } from "../utils/hr-dialog"

const useStyles = makeStyles(theme => ({
  content: {
    minHeight: "600px",
    display: "flex",
    position: "absolute",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "600px",
    padding: "32x",
    [theme.breakpoints.down("sm")]: {
      minHeight: "600px",
      padding: "32px",
    },
  },
  background: {
    display: "flex",
    justifyContent: "center",
    width: "100vw",
    minHeight: "600px",
    maxHeight: "600px",
    [theme.breakpoints.down("sm")]: {
      minHeight: "600px",
    },
  },
  button: {
    boxShadow: "none",
    textDecoration: "none !important",
    color: "#010000",
    fontSize: "16px",
    textTransform: "uppercase",
    paddingLeft: "22.5px",
    paddingRight: "22.5px",
    paddingTop: "15.5px",
    paddingBottom: "15.5px",
    backgroundColor: "#F26522",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#FF6a24",
    },
    "&:active": {
      boxShadow: "none",
    },
  },
}))

const CheckoutPositions = ({ positions }) => {
  const classes = useStyles()
  const openHrDialog = useOpenHrDialog()

  return (
    <CardMedia className={classes.background}>
      <Img
        fluid={positions.PositionBg.imageFile.childImageSharp.fluid}
        className={classes.background}
      />
      <Box className={classes.content}>
        <Typography
          align="center"
          variant="h1"
          style={{
            marginBottom: "16px",
            filter: "drop-shadow(4px 4px 8px #000b)",
          }}
        >
          {positions.PositionsTitle}
        </Typography>
        <Typography
          align="center"
          variant="body1"
          style={{
            filter: "drop-shadow(4px 4px 8px #000b)",
          }}
        >
          {positions.PositionsDescriptionFirst}
        </Typography>
        <Typography
          align="center"
          variant="body1"
          style={{
            marginBottom: "32px",
            filter: "drop-shadow(4px 4px 8px #000b)",
          }}
        >
          {positions.PositionDescriptionSecond}
        </Typography>
        <Button variant="contained" onClick={openHrDialog}>
          {positions.PositionsButton}
        </Button>
      </Box>
    </CardMedia>
  )
}

export default CheckoutPositions
