import { Typography, CardMedia, makeStyles, Box } from "@material-ui/core"
import React from "react"
import Img from "gatsby-image"

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: "relative",
    display: "flex",
    alignItems: "start",
    minHeight: "280px",
    minWidth: "360px",
    width: "100% !important",
    padding: "16px",
    justifyContent: "start",
    flexDirection: "column",
    margin: "auto",

    [theme.breakpoints.down("xs")]: {
      padding: "0px",
      paddingBottom: "16px",
      minHeight: "200px",
      maxWidth: "initial",
      justifyContent: "center",
    },
  },
  box: {
    position: "absolute",
    maxWidth: "300px",
    marginLeft: "32px",
    paddingTop: "50px",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "0px",
    },
  },
}))

const Fact = ({ title, description, image }) => {
  const classes = useStyles()
  return (
    <CardMedia className={classes.wrapper} border="1px solid white">
      <Img
        fixed={image.imageFile.childImageSharp.fixed}
        className={classes.wrapper}
      />
      <Box className={classes.box}>
        <Typography
          variant="h3"
          color="primary"
          style={{
            marginBottom: "16px",
            fontWeight: "700",
            filter: "drop-shadow(2px 2px 4px #000)",
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          style={{
            filter: "drop-shadow(2px 2px 2px #000f)",
            fontSize: "14px",
            color: "#FFF",
            fontWeight: "500",
          }}
        >
          {description}
        </Typography>
      </Box>
    </CardMedia>
  )
}

export default Fact
