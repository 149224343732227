import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout/layout-home"
import Hero from "../components/join-us/hero"
import Philosophy from "../components/join-us/philosophy"
import FactsContainer from "../components/join-us/facts-container"
import WellBeing from "../components/join-us/well-being"
import CheckoutPositions from "../components/join-us/checkout-positions"

const JoinUSPage = () => {
  const data = useStaticQuery(graphql`
    {
      strapi {
        joinUsPage {
          JoinUsHeroTitle
          JoinUsHeroLead
          JoinUsHeroButton
          JoinUsHeroVideoLink
          JoinUsPhilosophyTitle
          JoinUsPhilosophyDescription
          JoinUsPhilosophyBg {
            url
            imageFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          JoinUsFactTitle
          FactCards {
            FactCardTitle
            FactCardDescription
            FactCardBg {
              url
              imageFile {
                childImageSharp {
                  fixed(width: 300) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
          EmployeeWellTitle
          EmployeeWellDecsription
          EmployeeWellPoints {
            PointTitle
            PointDescription
          }
          EmployeeGallery {
            EmployeeImage {
              url
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          PositionsTitle
          PositionsDescriptionFirst
          PositionDescriptionSecond
          PositionsButton
          PositionBg {
            url
            imageFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Hero hero={data.strapi.joinUsPage} />
      <Philosophy philo={data.strapi.joinUsPage} />
      <FactsContainer facts={data.strapi.joinUsPage} />
      <WellBeing well={data.strapi.joinUsPage} />
      <CheckoutPositions positions={data.strapi.joinUsPage} />
    </Layout>
  )
}

export default JoinUSPage
